import { QueryClient } from '@tanstack/react-query'
import { HTTPError } from 'ky'

import { HttpErrorCodes } from '../types/api'

const NO_RETRY_ERROR_CODES = [HttpErrorCodes.NotFound]

export const queryClientFactory = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchInterval: 30 * 1000,
        refetchOnReconnect: 'always',
        staleTime: 30 * 1000,
        refetchIntervalInBackground: false,
        retry: (retryCount, error) => {
          if (error instanceof HTTPError) {
            if (NO_RETRY_ERROR_CODES.includes(error.response.status))
              return false
          }

          return retryCount < 3
        },
      },
    },
  })

const queryClient = queryClientFactory()

export default queryClient
