import type React from 'react'

import { AblyProvider, ChannelProvider } from 'ably/react'

import ablyClient from '../../api/services/ablyClient'
import { useAblyChannelName } from '../../api/sse/useSseEventListenerEffect'

export const withSseClientProvider =
  <T extends object>(
    Component: React.FunctionComponent<T>,
  ): React.FunctionComponent<T> =>
  props => (
    <AblyProvider client={ablyClient}>
      <ChannelProvider channelName={useAblyChannelName()}>
        <Component {...props} />
      </ChannelProvider>
    </AblyProvider>
  )
