import { compose, type Path } from 'ramda'

import {
  authFailureHandler,
  refreshToken,
} from 'src/entities/auth/services/authentication'
import { getToken } from 'src/entities/auth/services/token'
import {
  getRequest as apiGet,
  postRequest as apiPost,
  type ApiClient,
} from './api'
import { addProp } from '../../common/services/helpers/helpers'
import { type ApiRequest, type ApiResponse, type Page } from '../types/api'

const addApiProp = <T>(path: Path) => addProp<T, ApiRequest, ApiResponse>(path)

const addBaseUrl = addApiProp<string>(['prefixUrl'])
const addRestaurantHash = addApiProp<string>(['json', 'restaurant_hash'])
const addTeamMemberId = addApiProp<number>(['json', 'team_id'])
const addCurrentTeamMemberId = addApiProp<number>(['json', 'current_team_id'])
const addOffset = addApiProp<number>(['json', 'offset'])
const addLimit = addApiProp<number>(['json', 'limit'])
const addSearchBefore = addApiProp<string>(['json', 'search_before'])
const addSearchAfter = addApiProp<string>(['json', 'search_after'])
const addCursorAfter = addApiProp<string>(['json', 'page', 'after'])
const addCursorBefore = addApiProp<string>(['json', 'page', 'before'])
const addCursorSize = addApiProp<number>(['json', 'page', 'size'])

export type SortDirection = 'asc' | 'desc'
export const addSortDirection = addApiProp<SortDirection>(['json', 'sort'])

export const addAbortSignal = addApiProp<AbortSignal>(['signal'])

const addToken = () =>
  addApiProp<string>(['headers', 'Authorization'])(`Bearer ${getToken()}`)

const addCredentials = () => addApiProp<string>(['credentials'])('include')

const withAuthentication = (client: ApiClient) =>
  compose(addToken(), addCredentials())(client)

export const addAuthentication =
  (client: ApiClient) => (config: ApiRequest) => {
    if (!getToken()) {
      return refreshToken(client)
        .then(() => withAuthentication(client)(config))
        .catch(authFailureHandler(withAuthentication(client)))
    }

    return withAuthentication(client)(config).catch((error: Error) =>
      authFailureHandler(withAuthentication(client))(error).then(() =>
        withAuthentication(client)(config),
      ),
    )
  }

export const DEFAULT_LIMIT = 50
export const addOffsetLimitPagination = (
  page: number,
  resultsPerPage = DEFAULT_LIMIT,
) => compose(addOffset(resultsPerPage * page), addLimit(resultsPerPage))

export const defaultPaginationParams: Page = {
  size: DEFAULT_LIMIT,
  before: null,
  after: null,
}

export const addCursorPagination = (pageParam: Page) => {
  const { before, after, size } = pageParam ?? {}

  return compose(
    addCursorBefore(before),
    addCursorAfter(after),
    addCursorSize(size),
  )
}

/** @deprecated */
export interface CursorPaginationParamsOld {
  searchBefore?: string
  searchAfter?: string
}

/** @deprecated */
export const defaultPaginationParamsOld: CursorPaginationParamsOld = {
  searchBefore: undefined,
  searchAfter: undefined,
}

/** @deprecated */
export const addCursorPaginationOld = (
  pageParam: CursorPaginationParamsOld,
  resultsPerPage = DEFAULT_LIMIT,
) => {
  const { searchBefore, searchAfter } = pageParam || {}

  return compose(
    addSearchBefore(searchBefore),
    addSearchAfter(searchAfter),
    addLimit(resultsPerPage),
  )
}

type GetCursorPageParam = ({ page }: { page: Page }) => Page | undefined

export const getNextCursorPageParam: GetCursorPageParam = ({ page }) => {
  if (!page.after) return undefined

  return { ...page, before: null }
}

export const getPrevCursorPageParam: GetCursorPageParam = ({ page }) => {
  if (!page.before) return undefined

  return { ...page, after: null }
}

export const getRequest = (apiUrl: string) =>
  compose(addBaseUrl(apiUrl))(apiGet)

export const postRequest = (
  apiUrl: string,
  restaurantHash?: string,
  teamMemberId?: number | null,
) =>
  compose(
    addBaseUrl(apiUrl),
    addRestaurantHash(restaurantHash),
    addTeamMemberId(teamMemberId),
    addCurrentTeamMemberId(teamMemberId),
  )(apiPost)
