import { useCallback, useMemo } from 'react'

import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
  type DefaultError,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { getMe } from '../api/meApi'
import { type Me } from '../types/me'

const CACHE_KEY = ['me']

const useMeQueryOptions = <T>(preventLogout = false) => {
  const apiClient = useApiClient({ handleLogout: !preventLogout })

  return useMemo(
    () =>
      queryOptions<Me, DefaultError, T>({
        queryKey: CACHE_KEY,
        queryFn: getMe(apiClient.get),
        staleTime: Infinity,
        refetchInterval: false,
      }),
    [apiClient.get],
  )
}

export const useEnsureMe = (preventLogout = false) => {
  const queryClient = useQueryClient()
  const options = useMeQueryOptions(preventLogout)

  return useCallback(
    () => queryClient.ensureQueryData({ ...options, retry: false }),
    [queryClient, options],
  )
}

export const useMeQuery = <T>(
  options: Partial<UseSuspenseQueryOptions<Me, DefaultError, T>> = {},
) =>
  useSuspenseQuery({
    ...useMeQueryOptions<T>(),
    ...options,
  })
