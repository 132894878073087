import { CronTime } from 'cron'
import ky, { type Options } from 'ky'
import type { CamelCase, SnakeCase } from 'type-fest'
import { z } from 'zod'

import { getUtcDayStart } from '../../range/services/date'
import { camelToSnakeCase, snakeToCamelCase } from '../../string/services/text'
import {
  ApiError,
  HttpErrorCodes,
  type ApiRequest,
  type ApiResponse,
} from '../types/api'

interface Modifier<T, V> {
  key?: (key: string) => string
  value?: (value: T) => V
}

type RecursiveFunction<T, V> = (obj: T) => V

const applyRecursively =
  <T, V>(modifiers: Modifier<T, V>): RecursiveFunction<T, V> =>
  (obj: T): V => {
    if (Array.isArray(obj)) return obj.map(applyRecursively(modifiers)) as V
    if (obj?.constructor !== Object) return (modifiers.value?.(obj) ?? obj) as V

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        modifiers.key?.(key) ?? key,
        applyRecursively(modifiers)(value as T),
      ]),
    ) as V
  }

export const convertKeys = <T, V>(converter: (key: string) => string) =>
  applyRecursively<T, V>({ key: converter })

export const convertKeysToCamelCase: <T>(input: T) => {
  [K in keyof T as CamelCase<K>]: T[K]
} = convertKeys(snakeToCamelCase)

export const convertKeysToSnakeCase: <T>(input: T) => {
  [K in keyof T as SnakeCase<K>]: T[K]
} = convertKeys(camelToSnakeCase)

const errorResponseSchema = z.preprocess(
  convertKeysToCamelCase,
  z.object({
    message: z.string(),
    longMessage: z.string(),
    errorNumber: z.number().optional(),
  }),
)

const api = ky.create({
  retry: 0,
  hooks: {
    beforeError: [
      async error => {
        try {
          const data = errorResponseSchema.parse(
            await error.response.clone().json(),
          )
          if (Object.values(HttpErrorCodes).includes(error.response.status))
            return new ApiError(
              error,
              data.message,
              data.longMessage,
              data.errorNumber ?? 0,
              error.response.status,
            )
          return error
        } catch {
          return error
        }
      },
    ],
  },
})

export type ApiClient = (config: ApiRequest) => ApiResponse

const apiClient = async <T>(url: string, config: Options): Promise<T> => {
  const response = await api(url, config)
  return response.json()
}

export const postRequest: ApiClient = ({ url, ...config }) =>
  apiClient(url, {
    method: 'POST',
    ...config,
  })

export const getRequest: ApiClient = ({ url, ...config }) =>
  apiClient(url, {
    ...config,
    method: 'GET',
  })

export const dateSerializer = <T>(value: Date | T) => {
  if (!(value instanceof Date)) return value

  return value.toISOString()
}

const dateNormalizer = <T>(value: Date | T) => {
  if (!(value instanceof Date)) return value

  return getUtcDayStart(value)
}

export const convertDatesToNormalized = applyRecursively({
  value: dateNormalizer,
})

const isNumeric = (n: string) => {
  const floatn = parseFloat(n)
  return !Number.isNaN(floatn) && Number.isFinite(floatn)
}

export const numericSerializer = <T>(value: T) => {
  if (typeof value === 'string' && isNumeric(value))
    return Number.parseFloat(value)

  return value
}

export const serializeValues = (
  ...serializers: (<T>(...args: T[]) => string | number | T)[]
) => {
  const composeReducer = <T>(value: string | number | T) =>
    serializers.reduce((result, serializer) => serializer(result), value)

  return applyRecursively({ value: composeReducer })
}

export const getFlatPaginatedItems = <T>(pages: { data: T[] }[]) =>
  pages.flatMap(({ data }) => data)

export const getFlatPaginatedItemsLength = <T>(pages: { data: T[] }[]) =>
  getFlatPaginatedItems(pages).length

export const create1MinuteCronTime = () => {
  const secondOffset = Math.round(59 * Math.random())

  return new CronTime(`${secondOffset} * * * * *`)
}

export const create5MinuteCronTime = () => {
  const secondOffset = Math.round(59 * Math.random())
  const minuteOffset = Math.round(4 * Math.random())

  return new CronTime(`${secondOffset} ${minuteOffset}-59/5 * * * *`)
}
