import { useCallback, useMemo } from 'react'
import { compose, composeWith, otherwise } from 'ramda'

import { useLogoutMutation } from 'src/entities/auth/queries/auth'
import { getApiUrl } from '../../context/state/ApiClientConfigContext'
import { useActiveTeamMemberAtom } from '../../context/state/atoms/activeTeamMember'
import { useRestaurantHash } from '../../context/state/atoms/selectedRestaurant'
import { type ApiClient } from '../services/api'
import {
  addAuthentication,
  getRequest,
  postRequest,
} from '../services/reservationsBookApiClient'
import { TokenRefreshError } from '../types/api'

interface UseApiClient {
  withAuthentication?: boolean
  handleLogout?: boolean
}

export const useApiClient = ({ handleLogout = true }: UseApiClient = {}) => {
  const restaurantHash = useRestaurantHash()
  const [teamMemberId] = useActiveTeamMemberAtom()

  const { apiUrl } = getApiUrl()

  const { mutateAsync: logoutHandler } = useLogoutMutation()

  const tokenRefreshErrorHandler = useCallback(
    async (error: TokenRefreshError | Error) => {
      if (!(error instanceof TokenRefreshError)) throw error
      if (!handleLogout) return

      await logoutHandler()
    },
    [handleLogout, logoutHandler],
  )

  return useMemo(
    () => ({
      post: composeWith(otherwise)([
        tokenRefreshErrorHandler,
        compose(addAuthentication, postRequest)(
          apiUrl,
          restaurantHash,
          teamMemberId,
        ),
      ]) as ApiClient,
      get: composeWith(otherwise)([
        tokenRefreshErrorHandler,
        compose(addAuthentication, getRequest)(apiUrl),
      ]) as ApiClient,
    }),
    [apiUrl, restaurantHash, teamMemberId, tokenRefreshErrorHandler],
  )
}
