import type React from 'react'

import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { LoginScreen } from './LoginScreen'

const withLoginGuard = (Component: React.FunctionComponent) => () => {
  const { open, handleClose } = useDialog(true)

  if (open) return <LoginScreen onLogin={handleClose} />

  return <Component />
}

export default withLoginGuard
