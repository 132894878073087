import React from 'react'
import { compose } from 'ramda'

import withLoginGuard from 'src/pages/Login/withLoginGuard'
import { withHelmet } from 'src/shared/components/common/Meta/Meta'
import {
  withAppContainer,
  withToaster,
} from 'src/shared/components/containers/AppContainer/AppContainer'
import { withSseClientProvider } from 'src/shared/lib/context/service/SseClientContext'
import { withAppProvider } from '../../hoc/withAppProvider'
import { withGlobalErrorBoundary } from '../../hoc/withGlobalErrorBoundary'

const App = React.lazy(() => import('./App'))
const MaintenanceMode = React.lazy(
  () => import('src/pages/Login/MaintenanceMode'),
)

const isMaintenanceMode = import.meta.env.PUBLIC_MAINTENANCE_MODE

const AppWithLoginGuard = withLoginGuard(App)

const AppView = () => {
  if (isMaintenanceMode) return <MaintenanceMode />

  return <AppWithLoginGuard />
}

export default compose(
  withGlobalErrorBoundary,
  withSseClientProvider,
  withHelmet,
  withAppProvider,
  withAppContainer,
  withToaster,
)(AppView)
